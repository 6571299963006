<template>
  <b-card v-if="user.data" title="Manage your keys">
	<p class="mb-2">
		Use an API key to authenticate your API requests with your account. Learn how to create an API request <a href="https://docs.abracadalo.com/create-request" target="_blank">here</a>.
	</p>
    <div class="mb-2">
		<span v-if="apiKeys.length >= 10"
			class="d-inline-block" tabindex="0"
			v-b-tooltip.right title="You already reached the maximum amount of API keys.">
			<b-button
				variant="primary"
				disabled
			>Generate New API Key</b-button>
		</span>
		<span v-else class="d-inline-block" tabindex="0">
			<b-button
				variant="primary"
				@click="generateKey()"
			>Generate New API Key</b-button>
		</span>
	</div>
	<b-table v-if="apiKeys.length > 0" :items="apiKeys" :fields="fields">
		<template #cell(value)="data">
			<hidden-text :value="data.value" :key="data.value" />
		</template>
		<template #cell(actions)="data">
			<b-button variant="danger" @click="openConfirm(data.item.value)">Delete</b-button>
		</template>
	</b-table>
  </b-card>
</template>

<script>
import { BCard, BCardText, BButton, BTable, VBTooltip, BAlert } from 'bootstrap-vue'
import HiddenText from '../components/HiddenText.vue'
import { addDoc, collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore"
import { mapGetters } from "vuex";

export default {
	components: {
		BCard,
		BCardText,
		BButton,
		BTable,
		HiddenText,
		BAlert
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	data: () => ({
		apiKeys: [],
		fields: [
			{ key: 'value', label: 'API Key' },
			{ key: 'created', label: 'Created At' },
			{ key: 'actions', label: 'Actions' }
		]
	}),
	computed: {
		...mapGetters({
			user: "auth/user"
		})
	},
	watch: {
		"user.data.uid": {
			immediate: true,
			handler() {
				this.fetchApiKeys();
			}
		}
	},
	methods: {
		async fetchApiKeys() {
			const q = query(collection(this.$firestore, "api-keys"), where("uid", "==", this.user.data.uid))
			const apiKeyDocs = await getDocs(q);

			const apiKeys = [];
			apiKeyDocs.forEach((doc) => {
				apiKeys.push({
					value: doc.id,
					created: doc.data().created.toDate().toLocaleString("en-US")
				})
			});
			this.apiKeys = apiKeys;
		},
		async generateKey() {
			if (this.apiKeys.length >= 10) return;

			await addDoc(collection(this.$firestore, "api-keys"), {
				uid: this.user.data.uid,
				created: new Date()
			});
			await this.fetchApiKeys()
		},
		openConfirm(apiKey) {
			this.$swal({
				title: 'Are you sure?',
				text: "Requests using this API key will not work anymore. This also affects users who use a version of your application that works with this API key! This action cannot be undone.",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (!result.value) return;

				deleteDoc(doc(this.$firestore, "api-keys", apiKey)).then(() => {
					this.fetchApiKeys();
					this.$swal({
						icon: 'success',
						title: 'Deleted!',
						text: 'The API key has been deleted.',
						customClass: {
							confirmButton: 'btn btn-success',
						},
					})
				}).catch(console.error)
			})
		},
	}
}
</script>

<style>

</style>
