<template>
	<div class="d-flex">
		<div v-if="visible" class="hidden-text-value">{{ value }}</div>
		<div v-else class="hidden-text-value">{{ "&#8727;".repeat(20) }}</div>
		<b-button variant="link" v-if="visible" @click="toggle">Hide</b-button>
		<b-button variant="link" v-else @click="toggle">Show</b-button>
		<b-button variant="link" @click="copy">Copy</b-button>
	</div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
	components: {
		BButton,
	},
	props: {
		show: {
			type: Boolean,
			default: false
		},
		value: String
	},
	data: () => ({
		visible: false
	}),
	created() {
		this.visible = this.show;
	},
	methods: {
		toggle() {
			this.visible = !this.visible
		},
		copy() {
			navigator.clipboard.writeText(this.value)
		}
	}
}
</script>

<style lang="scss" scoped>
.btn-link {
	padding: 0;
	margin-left: 2em;
}
.hidden-text-value {
	width: 200px;
}
</style>
